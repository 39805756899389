// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-domain-js": () => import("./../../../src/pages/domain.js" /* webpackChunkName: "component---src-pages-domain-js" */),
  "component---src-pages-industry-js": () => import("./../../../src/pages/industry.js" /* webpackChunkName: "component---src-pages-industry-js" */),
  "component---src-templates-about-us-js": () => import("./../../../src/templates/aboutUs.js" /* webpackChunkName: "component---src-templates-about-us-js" */),
  "component---src-templates-benefits-js": () => import("./../../../src/templates/benefits.js" /* webpackChunkName: "component---src-templates-benefits-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-brand-js": () => import("./../../../src/templates/Brand.js" /* webpackChunkName: "component---src-templates-brand-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-current-openings-js": () => import("./../../../src/templates/currentOpenings.js" /* webpackChunkName: "component---src-templates-current-openings-js" */),
  "component---src-templates-domain-page-js": () => import("./../../../src/templates/domainPage.js" /* webpackChunkName: "component---src-templates-domain-page-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-industry-js": () => import("./../../../src/templates/industry.js" /* webpackChunkName: "component---src-templates-industry-js" */),
  "component---src-templates-job-js": () => import("./../../../src/templates/job.js" /* webpackChunkName: "component---src-templates-job-js" */),
  "component---src-templates-kartavya-csr-initiative-js": () => import("./../../../src/templates/kartavya-csr-initiative.js" /* webpackChunkName: "component---src-templates-kartavya-csr-initiative-js" */),
  "component---src-templates-leader-js": () => import("./../../../src/templates/leader.js" /* webpackChunkName: "component---src-templates-leader-js" */),
  "component---src-templates-life-at-verinite-js": () => import("./../../../src/templates/life-at-verinite.js" /* webpackChunkName: "component---src-templates-life-at-verinite-js" */),
  "component---src-templates-mission-page-js": () => import("./../../../src/templates/missionPage.js" /* webpackChunkName: "component---src-templates-mission-page-js" */),
  "component---src-templates-news-event-js": () => import("./../../../src/templates/news-event.js" /* webpackChunkName: "component---src-templates-news-event-js" */),
  "component---src-templates-news-event-landing-js": () => import("./../../../src/templates/newsEventLanding.js" /* webpackChunkName: "component---src-templates-news-event-landing-js" */),
  "component---src-templates-pages-js": () => import("./../../../src/templates/pages.js" /* webpackChunkName: "component---src-templates-pages-js" */),
  "component---src-templates-partnership-js": () => import("./../../../src/templates/partnership.js" /* webpackChunkName: "component---src-templates-partnership-js" */),
  "component---src-templates-people-bytes-js": () => import("./../../../src/templates/peopleBytes.js" /* webpackChunkName: "component---src-templates-people-bytes-js" */),
  "component---src-templates-policy-js": () => import("./../../../src/templates/policy.js" /* webpackChunkName: "component---src-templates-policy-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-post-sidebar-js": () => import("./../../../src/templates/postSidebar.js" /* webpackChunkName: "component---src-templates-post-sidebar-js" */),
  "component---src-templates-resource-listing-js": () => import("./../../../src/templates/resource-listing.js" /* webpackChunkName: "component---src-templates-resource-listing-js" */),
  "component---src-templates-selfie-js": () => import("./../../../src/templates/selfie.js" /* webpackChunkName: "component---src-templates-selfie-js" */),
  "component---src-templates-service-landing-js": () => import("./../../../src/templates/service-landing.js" /* webpackChunkName: "component---src-templates-service-landing-js" */),
  "component---src-templates-service-listing-js": () => import("./../../../src/templates/serviceListing.js" /* webpackChunkName: "component---src-templates-service-listing-js" */),
  "component---src-templates-service-product-management-js": () => import("./../../../src/templates/service-product-management.js" /* webpackChunkName: "component---src-templates-service-product-management-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-tech-integration-js": () => import("./../../../src/templates/tech-integration.js" /* webpackChunkName: "component---src-templates-tech-integration-js" */),
  "component---src-templates-tech-vision-js": () => import("./../../../src/templates/tech-vision.js" /* webpackChunkName: "component---src-templates-tech-vision-js" */),
  "component---src-templates-webinar-js": () => import("./../../../src/templates/webinar.js" /* webpackChunkName: "component---src-templates-webinar-js" */)
}

